import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_backstretch = _resolveComponent("app-backstretch")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_backstretch, {
      id: "backstretch0",
      class: "backstretch out",
      resizeToWindowView: false,
      image: _ctx.curBackgroundImage[0],
      width: _ctx.previewWidth,
      height: _ctx.previewHeight,
      focusYinPercent: 50,
      focusXinPercent: 50
    }, null, 8, ["image", "width", "height"]),
    _createVNode(_component_app_backstretch, {
      id: "backstretch1",
      class: "backstretch in",
      resizeToWindowView: false,
      image: _ctx.curBackgroundImage[1],
      width: _ctx.previewWidth,
      height: _ctx.previewHeight,
      focusYinPercent: 50,
      focusXinPercent: 50
    }, null, 8, ["image", "width", "height"])
  ], 64))
}