import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-backstretch",
    props: {
        resizeToWindowView: {
            type: Boolean,
            default: true,
        },
        heightPercentageOfWindow: {
            type: Number,
            default: 100,
        },
        widthPercentageOfWindow: {
            type: Number,
            default: 100,
        },
        width: {
            type: Number,
            default: undefined,
        },
        height: {
            type: Number,
            default: undefined,
        },
        image: {
            type: String,
            required: true,
        },
        focusXinPercent: {
            type: Number,
            default: 100,
        },
        focusYinPercent: {
            type: Number,
            default: 100,
        },
    },
    watch: {
        width: function () {
            this.computeStyle();
        },
        height: function () {
            this.computeStyle();
        },
        image: function () {
            this.computeStyle();
        },
        focusXinPercent: function () {
            this.computeStyle();
        },
        focusYinPercent: function () {
            this.computeStyle();
        },
    },
})
export default class Backstretch extends Vue {
    private computedStyle: any = {};
    private focusYinPercent: number;
    private focusXinPercent: number;
    private resizeToWindowView: boolean;
    private heightPercentageOfWindow: number;
    private widthPercentageOfWindow: number;
    private image: string;
    private width: number;
    private height: number;

    public mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }
    public unmounted() {
        window.removeEventListener("resize", this.handleResize);
    }

    private static inPixels(size: number) {
        return size + "px";
    }
    private static inPercent(size: number) {
        return size + "%";
    }
    private handleResize() {
        this.computeStyle();
    }
    private computeStyle() {
        const backgroundImage = this.image ? `url('${this.image}')` : "none";
        const defaultStyle = {
            "background-position-y": this.focusYinPercent ? Backstretch.inPercent(this.focusYinPercent) : Backstretch.inPercent(0),
            "background-position-x": this.focusXinPercent ? Backstretch.inPercent(this.focusXinPercent) : Backstretch.inPercent(0),
            backgroundImage,
        };

        if (this.resizeToWindowView) {
            // const computedHeight =
            //     this.heightPercentageOfWindow === 100
            //         ? window.outerHeight
            //         : window.outerHeight *
            //           (this.heightPercentageOfWindow / 100);
            // const computedWidth =
            //     this.widthPercentageOfWindow === 100
            //         ? window.outerWidth
            //         : window.outerWidth * (this.widthPercentageOfWindow / 100);

            this.computedStyle = {
                //width: BackgroundImages.inPixels(computedWidth),
                //height: BackgroundImages.inPixels(computedHeight),
                ...defaultStyle,
            };
        } else {
            this.computedStyle = {
                //width: BackgroundImages.inPixels(this.width),
                //height: BackgroundImages.inPixels(this.height),
                ...defaultStyle,
            };
        }
    }
}
